<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16663 3.33332H5.66663C4.26649 3.33332 3.56643 3.33332 3.03165 3.6058C2.56124 3.84549 2.17879 4.22794 1.93911 4.69834C1.66663 5.23312 1.66663 5.93319 1.66663 7.33332V14.3333C1.66663 15.7335 1.66663 16.4335 1.93911 16.9683C2.17879 17.4387 2.56124 17.8212 3.03165 18.0608C3.56643 18.3333 4.26649 18.3333 5.66663 18.3333H12.6666C14.0668 18.3333 14.7668 18.3333 15.3016 18.0608C15.772 17.8212 16.1545 17.4387 16.3941 16.9683C16.6666 16.4335 16.6666 15.7335 16.6666 14.3333V10.8333M6.6666 13.3333H8.06206C8.46971 13.3333 8.67353 13.3333 8.86535 13.2873C9.03541 13.2464 9.19798 13.1791 9.3471 13.0877C9.5153 12.9847 9.65942 12.8405 9.94767 12.5523L17.9166 4.58332C18.607 3.89296 18.607 2.77368 17.9166 2.08332C17.2263 1.39296 16.107 1.39296 15.4166 2.08332L7.44765 10.0523C7.1594 10.3405 7.01527 10.4847 6.9122 10.6528C6.82082 10.802 6.75348 10.9645 6.71265 11.1346C6.6666 11.3264 6.6666 11.5302 6.6666 11.9379V13.3333Z"
      stroke="currentcolor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
